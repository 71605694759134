import React, { useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { localStoragePrefix } from "../../../utils/config";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";
import {
  addPricingPlanAdmin,
  getAllPricingPlansListAdmin,
} from "../../../redux/actions/admin/pricingPlans";
import dummyImg from "../../assets/images/dummy-image.jpg";
import { adminCreatePricingPlanSachema } from "./components/Validations";
import leftArrowIcon from "../../assets/images/arrow-left.png";

const AddPricingPlan = () => {
  const dispatch = useDispatch();
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    price: "",
    price_string: "",
    duration: "",
    status: "1",
    description: "",
    features: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Untititled
  const [unlimited, setUnlimited] = useState({
    status: false,
    screenshots: -1,
    videos: -1,
    message: "",
  });
  // Watermark
  const [watermark, setWatermark] = useState({
    status: false,
    message: "",
  });
  // UrlSummary
  const [urlSummary, setUrlSummary] = useState({
    status: false,
    message: "",
  });

  // UnlimitedLength
  const [unlimitedLength, setUnlimitedLength] = useState({
    status: false,
    length: -1,
    message: "",
  });

  // Gradient
  const [gradient, setGradient] = useState({
    status: false,
    message: "",
  });

  // Emoji
  const [emoji, setEmoji] = useState({
    status: false,
    message: "",
  });

  // Crop pip
  const [cropPip, setCropPip] = useState({
    status: false,
    message: "",
  });

  // Media Crop Option
  const [mediaCropOption, setMediaCropOption] = useState({
    status: false,
    message: "",
  });

  // Call out
  const [callOut, setCallOut] = useState({
    status: false,
    message: "",
  });

  // Gif
  const [gif, setGif] = useState({
    status: false,
    message: "",
  });

  // Resolution
  const [resolution, setResolution] = useState({
    status: false,
    resolutions: {
      "360p": false,
      "480p": false,
      "720p": false,
      "1080p": false,
      "4K": false,
    },
    message: "",
  });

  // Recovery
  const [recovery, setRecovery] = useState({
    status: false,
    message: "",
  });

  // UserManagement
  const [userManagement, setUserManagement] = useState({
    status: false,
    message: "",
  });

  // Integrations
  const [integrations, setIntegrations] = useState({
    status: false,
    integration_media: {
      facebook: false,
      linkedin: false,
      twitter: false,
      whats_app: false,
      telegram: false,
      evernote: false,
      dropbox: false,
      notion: false,
      slack: false,
      drive: false,
      youtube: false,
      team: false,
      share_point: false,
      onenote: false,
      email: false,
      qr_code: false,
      third_party: false,
    },
    message: "",
  });

  // Speech to Text
  const [speechToText, setSpeechToText] = useState({
    status: false,
    message: "",
  });

  // Text to Speech
  const [textToSpeech, setTextToSpeech] = useState({
    status: false,
    message: "",
  });

  // Download
  const [download, setDownload] = useState({
    status: false,
    formats: {
      videos: {
        webm: true,
        mp4: false,
      },
      screenshots: {
        png: true,
        jpg: false,
        pdf: false,
      },
    },
    message: "",
  });

  // AdSense
  const [adSense, setAdSense] = useState({
    status: false,
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const fileFormat = ["image/jpg", "image/jpeg", "image/png"];

  const initialState = {
    alt: "photo",
    src: dummyImg,
  };

  const [{ alt, src }, setPreview] = useState(initialState);
  const [showPhoto, setPhoto] = useState(null);

  const handleUploadImage = (e) => {
    let file = e.target.files;
    let fileType = file?.[0]?.type;
    if (fileFormat.indexOf(fileType) > -1) {
      setPreview(
        file?.length
          ? {
            src: URL.createObjectURL(file?.[0]),
            alt: file?.[0]?.name,
          }
          : initialState
      );
      setPhoto(file?.[0]);
    } else {
      errorMessageModal(
        "Unsupported File Format",
        "Please upload an image in PNG, JPEG, or JPG format"
      );
    }
  };

  const removeUploadedImage = () => {
    const initialState = {
      alt: "photo",
      src: dummyImg,
    };
    setPreview(initialState);
    setPhoto(null);
  }

  const [formErrors, setFormErrors] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const featuredObj = {
      Unlimited: unlimited,
      Watermark: watermark,
      UnlimitedLength: unlimitedLength,
      UrlSummary: urlSummary,
      Gif: gif,
      Gradient: gradient,
      Emoji: emoji,
      CropPip: cropPip,
      MediaCropOption: mediaCropOption,
      CallOut: callOut,
      Resolution: resolution,
      Recovery: recovery,
      UserManagement: userManagement,
      Integrations: integrations,
      SpeechToText: speechToText,
      TextToSpeech: textToSpeech,
      Download: download,
      AdSense: adSense
    };
    const payload = {
      admin_id: Number(userID),
      price: formData?.price,
      picture: showPhoto,
      price_string: formData?.price_string,
      duration: formData?.duration,
      status: formData?.status,
      description: formData?.description,
      features: JSON.stringify(featuredObj),
    };
    if (adminCreatePricingPlanSachema({ formData: payload, setFormErrors })) {
      setLoading(true);
      dispatch(
        addPricingPlanAdmin(payload, (type, res) => {
          if (type === "success") {
            setLoading(false);
            successMessageModal(
              "Pricing Plan Successfully Created.",
              "Pricing plan has been created successfully."
            );
            setTimeout(() => {
              navigate("/admin/manage-pricing-plans");
            }, 2000);
            dispatch(getAllPricingPlansListAdmin(Number(userID)));
          } else if (type === "fail") {
            setLoading(false);
            errorMessageModal("Create Pricing Plan", res?.message);
          }
        })
      );
    }
  };

  const resetForm = () => {
    document.getElementById("create-pricing-plan-form").reset();
    // formdetails
    setFormData({
      price: "",
      price_string: "",
      duration: "",
      description: "",
      features: "",
    });

    // unlimited
    setUnlimited({
      status: false,
      screenshots: -1,
      videos: -1,
      message: "",
    });

    // Watermark
    setWatermark({
      status: false,
      message: "",
    });

    // Url Summary
    setUrlSummary({
      status: false,
      message: "",
    });

    // UnlimitedLength
    setUnlimitedLength({
      status: false,
      length: -1,
      message: "",
    });

    // Gradient
    setGradient({
      status: false,
      message: "",
    });

    // Emoji
    setEmoji({
      status: false,
      message: "",
    });

    // Crop pip
    setCropPip({
      status: false,
      message: "",
    });

    // Media Crop Option
    setMediaCropOption({
      status: false,
      message: "",
    });

    // Call out
    setCallOut({
      status: false,
      message: "",
    });

    // Gif
    setGif({
      status: false,
      message: "",
    });

    // Resolution
    setResolution({
      status: false,
      resolutions: {
        "360p": false,
        "480p": false,
        "720p": false,
        "1080p": false,
        "4K": false,
      },
      message: "",
    });

    // Recovery
    setRecovery({
      status: false,
      message: "",
    });

    // UserManagement
    setUserManagement({
      status: false,
      message: "",
    });

    // Integrations
    setIntegrations({
      status: false,
      integration_media: {
        facebook: false,
        linkedin: false,
        twitter: false,
        whats_app: false,
        telegram: false,
        evernote: false,
        dropbox: false,
        notion: false,
        slack: false,
        drive: false,
        youtube: false,
        team: false,
        share_point: false,
        onenote: false,
        email: false,
        qr_code: false,
        third_party: false,
      },
      message: "",
    });

    // Speech to Text
    setSpeechToText({
      status: false,
      message: "",
    });

    // Text to Speech
    setTextToSpeech({
      status: false,
      message: "",
    });

    // AdSense
    setAdSense({
      status: false,
      message: "",
    });

    // Download
    setDownload({
      status: false,
      formats: {
        videos: {
          webm: false,
          mp4: false,
        },
        screenshots: {
          png: false,
          jpg: false,
          pdf: false,
        },
      },
      message: "",
    });
  };

  return (
    <div>
      <AdminLayout pageTitle={"pricing-plans"}>
        <div className="pricing-plans-content">
          <div className="pricing-plans-content">
            <div className="table-management-section">
              <div className="management-header mb-5">
                <Link
                  to={"/admin/manage-pricing-plans"}
                  className="user-manage-bk-btn theme-btn-outline btn mb-3"
                >
                  <img src={leftArrowIcon} />
                  Back to Pricing Plan Settings
                </Link>
                <h4>Create Product Plan</h4>
                <p>You can create a pricing plan.</p>
              </div>
            </div>
          </div>
          <div className="quix-data-table card">
            <div className="card-header">
              <h5 className="title m-0">Product Plan informations</h5>
            </div>
            <div className="card-body">
              <div className="quix-form-outer">
                <div className="quix-add-pricing-plan">
                  <form onSubmit={handleSubmit} id="create-pricing-plan-form">
                    <div className="row">
                      <div className="col-md-12 product-upload-img d-flex gap-3 align-items-center">
                        <div className="card upload-card">
                          <img src={src} height={"100%"} width={"100%"} />
                          <input
                            type="file"
                            name="upload-img"
                            className="upload-pricing-img"
                            onChange={(e) => handleUploadImage(e)}
                            accept={fileFormat.join(",")}
                          />
                        </div>
                        <div className="upload-cover mt-2">
                          <div className="d-flex gap-2">
                            <div className="upload-btn upload-card">
                              <input
                                type="file"
                                name="upload-img"
                                accept={fileFormat.join(",")}
                                onChange={(e) => handleUploadImage(e)}
                                className="upload-pricing-img"
                              />
                              <button
                                type="button"
                                className="btn upload-photo-btn"
                              >
                                Upload photo
                              </button>
                            </div>
                            <button
                              type="button"
                              className="btn remove-photo-btn"
                              onClick={() => removeUploadedImage()}
                            >
                              Remove photo
                            </button>
                          </div>
                          <p>at least 132 x 132px PNG, JPEG or JPG file.</p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="price"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Price (USD)
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="price"
                                id="price"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                              {formErrors?.priceError && (
                                <p className="text-danger account-dec m-0">
                                  {formErrors?.priceError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="duration"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Duration (In Month)
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="duration"
                                id="duration"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                              {formErrors?.durationError && (
                                <p className="text-danger account-dec m-0">
                                  {formErrors?.durationError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="status"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Status
                            </label>
                            <div className="col-sm-12">
                              <select
                                className="form-control"
                                type="text"
                                name="status"
                                id="status"
                                onChange={(e) => handleChange(e)}
                              >
                                <option value={"1"}>Active</option>
                                <option value={"0"}>Inactive</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor="price-string"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Price String
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="price_string"
                                id="price-string"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                              {formErrors?.priceStringError && (
                                <p className="text-danger account-dec m-0">
                                  {formErrors?.priceStringError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor="description"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Description
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="description"
                                id="description"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-12 col-form-label text-start"
                        >
                          Features
                        </label>
                        <div className="quixy-feature-row-outer">
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="unlimited"
                                onChange={(e) =>
                                  setUnlimited(() => ({
                                    ...unlimited,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="unlimited">Unlimited</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Screenshots
                                </label>
                                <input
                                  onChange={(e) =>
                                    setUnlimited(() => ({
                                      ...unlimited,
                                      screenshots: e?.target?.value,
                                    }))
                                  }
                                  name="screenshots"
                                  type="number"
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">Videos</label>
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setUnlimited(() => ({
                                      ...unlimited,
                                      videos: e?.target?.value,
                                    }))
                                  }
                                  name="videos"
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setUnlimited(() => ({
                                      ...unlimited,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  name="message"
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="download"
                                onChange={(e) =>
                                  setDownload(() => ({
                                    ...download,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="download">Download</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row d-block">
                              <div className="video-opt">
                                <label className="col-form-label">Video</label>
                                <div className="gap-3 d-flex">
                                  {Object.keys(download.formats.videos).map(
                                    (format) => (
                                      <div
                                        key={format}
                                        className="download-opt d-flex align-items-center gap-1"
                                      >
                                        <input
                                          type="checkbox"
                                          id={format}
                                          className="form-check-input"
                                          checked={
                                            download.formats.videos[format]
                                          }
                                          onChange={() => {
                                            setDownload((prevState) => ({
                                              ...prevState,
                                              formats: {
                                                ...prevState.formats,
                                                videos: {
                                                  ...prevState.formats.videos,
                                                  [format]:
                                                    !prevState.formats.videos[
                                                    format
                                                    ],
                                                },
                                              },
                                            }));
                                          }}
                                        />
                                        <label
                                          htmlFor={format}
                                          className="col-form-label"
                                        >
                                          {format}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="screen-opt">
                                <label className="col-form-label">
                                  Screenshot
                                </label>
                                <div className="d-flex align-items-center gap-3">
                                  {Object.keys(
                                    download.formats.screenshots
                                  ).map((format) => (
                                    <div
                                      key={format}
                                      className="download-opt d-flex align-items-center gap-1"
                                    >
                                      <input
                                        id={format}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={
                                          download.formats.screenshots[format]
                                        }
                                        onChange={() => {
                                          setDownload((prevState) => ({
                                            ...prevState,
                                            formats: {
                                              ...prevState.formats,
                                              screenshots: {
                                                ...prevState.formats
                                                  .screenshots,
                                                [format]:
                                                  !prevState.formats
                                                    .screenshots[format],
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                      <label
                                        htmlFor={format}
                                        className="col-form-label"
                                      >
                                        {format}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setDownload(() => ({
                                      ...download,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="unlimititedlength"
                                onChange={(e) =>
                                  setUnlimitedLength(() => ({
                                    ...unlimitedLength,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="unlimititedlength">
                                Unlimited Length
                              </label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Video Length (In Seconds)</label>
                                <input
                                  type="number"
                                  name="length"
                                  onChange={(e) =>
                                    setUnlimitedLength(() => ({
                                      ...unlimitedLength,
                                      length: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setUnlimitedLength(() => ({
                                      ...unlimitedLength,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="resolution"
                                onChange={(e) =>
                                  setResolution(() => ({
                                    ...resolution,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="resolution">Resolution</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row d-block">
                              <label>Resolution Formats</label>
                              <div className="features-colums">
                                {Object.keys(resolution.resolutions).map(
                                  (reslsn) => (
                                    <div
                                      key={reslsn}
                                      className="resolation-opt d-flex align-items-center gap-1"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={reslsn}
                                        name={reslsn}
                                        onChange={(e) =>
                                          setResolution((prevState) => ({
                                            ...prevState,
                                            resolutions: {
                                              ...prevState.resolutions,
                                              [reslsn]: e.target.checked,
                                            },
                                          }))
                                        }
                                      />
                                      <label
                                        htmlFor={reslsn}
                                        className="col-form-label"
                                      >
                                        {reslsn}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="message"
                                  onChange={(e) =>
                                    setResolution(() => ({
                                      ...resolution,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="watermark"
                                onChange={(e) =>
                                  setWatermark(() => ({
                                    ...watermark,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="watermark">Watermark</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setWatermark(() => ({
                                      ...watermark,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="urlSummary"
                                onChange={(e) =>
                                  setUrlSummary(() => ({
                                    ...urlSummary,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={
                                  urlSummary?.status === true ? true : false
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="urlSummary">Url Summary</label>
                            </div>
                            {/* {urlSummary?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setUrlSummary(() => ({
                                      ...urlSummary,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={urlSummary?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="gif"
                                onChange={(e) =>
                                  setGif(() => ({
                                    ...gif,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={gif?.status === true ? true : false}
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="gif">Gif</label>
                            </div>
                            {/* {gif?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setGif(() => ({
                                      ...gif,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={gif?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="gradient"
                                onChange={(e) =>
                                  setGradient(() => ({
                                    ...gradient,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="gradient">Gradient</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setGradient(() => ({
                                      ...gradient,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="emoji"
                                onChange={(e) =>
                                  setEmoji(() => ({
                                    ...emoji,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={emoji?.status === true ? true : false}
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="emoji">Emoji</label>
                            </div>
                            {/* {emoji?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setEmoji(() => ({
                                      ...emoji,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={emoji?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="cropPip"
                                onChange={(e) =>
                                  setCropPip(() => ({
                                    ...cropPip,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={cropPip?.status === true ? true : false}
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="cropPip">Crop PIP</label>
                            </div>
                            {/* {cropPip?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setCropPip(() => ({
                                      ...cropPip,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={cropPip?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="mediaCropOption"
                                onChange={(e) =>
                                  setMediaCropOption(() => ({
                                    ...mediaCropOption,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={mediaCropOption?.status === true ? true : false}
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="mediaCropOption">Media Crop Option</label>
                            </div>
                            {/* {mediaCropOption?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setMediaCropOption(() => ({
                                      ...mediaCropOption,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={mediaCropOption?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="callOut"
                                onChange={(e) =>
                                  setCallOut(() => ({
                                    ...callOut,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={callOut?.status === true ? true : false}
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="callOut">Callout</label>
                            </div>
                            {/* {cropPip?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setCallOut(() => ({
                                      ...callOut,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={callOut?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="recovery"
                                onChange={(e) =>
                                  setRecovery(() => ({
                                    ...recovery,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="recovery">Recovery</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="message"
                                  onChange={(e) =>
                                    setRecovery(() => ({
                                      ...recovery,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="usermanagement"
                                onChange={(e) =>
                                  setUserManagement(() => ({
                                    ...userManagement,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="usermanagement">
                                User Management
                              </label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setUserManagement(() => ({
                                      ...userManagement,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="integrations"
                                onChange={(e) =>
                                  setIntegrations(() => ({
                                    ...integrations,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={
                                  integrations?.status === true ? true : false
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="integrations">Integrations</label>
                            </div>
                            {/* {integrations?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="d-flex flex-wrap gap-3">
                                {Object.keys(integrations?.integration_media)?.map((media) =>
                                (
                                  <div
                                    key={media}
                                    className="resolation-opt d-flex align-items-center gap-1"
                                  >
                                    <input
                                      onChange={(e) =>
                                        setIntegrations((prevState) => ({
                                          ...prevState,
                                          integration_media: {
                                            ...prevState?.integration_media,
                                            [media]: e.target.checked,
                                          },
                                        }))
                                      }
                                      id={media}
                                      type="checkbox"
                                      name={media}
                                      checked={
                                        integrations?.integration_media?.[media]
                                      }
                                      className="form-check-input"
                                    />
                                    <label
                                      htmlFor={media}
                                      className="col-form-label text-capitalize"
                                    >
                                      {media?.replace('_', " ")}
                                    </label>
                                  </div>
                                )
                                )}
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setIntegrations(() => ({
                                      ...integrations,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={integrations?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="speechToText"
                                onChange={(e) =>
                                  setSpeechToText(() => ({
                                    ...speechToText,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={
                                  speechToText?.status === true ? true : false
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="speechToText">Speech to Text</label>
                            </div>
                            {/* {speechToText?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setSpeechToText(() => ({
                                      ...speechToText,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={speechToText?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="adsense"
                                onChange={(e) =>
                                  setAdSense(() => ({
                                    ...adSense,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={
                                  adSense?.status === true ? true : false
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="adsense">Google AdSense</label>
                            </div>
                            {/* {speechToText?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setAdSense(() => ({
                                      ...adSense,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={adSense?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="textToSpeech"
                                onChange={(e) =>
                                  setTextToSpeech(() => ({
                                    ...textToSpeech,
                                    status: e.target.checked,
                                  }))
                                }
                                checked={
                                  textToSpeech?.status === true ? true : false
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="textToSpeech">Text to Speech</label>
                            </div>
                            {/* {textToSpeech?.status === true && ( */}
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Message</label>
                                <input
                                  onChange={(e) =>
                                    setTextToSpeech(() => ({
                                      ...textToSpeech,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  type="text"
                                  name="message"
                                  value={textToSpeech?.message}
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="theme-form-btn d-flex align-items-center mt-2 gap-2">
                      <button
                        className="btn theme-blue-btn"
                        type="submit"
                        disabled={loading}
                      >
                        Save & Continue
                      </button>
                      <button
                        type="button"
                        onClick={() => resetForm()}
                        className="btn theme-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

export default AddPricingPlan;
