import React, { useEffect, useRef, useState } from "react";
import uninsatllImg from "../../assets/images/home/ocr-uninstall-image.png";
import logoImg from "../../assets/images/home/ocr-logo.png";
import { Link } from "react-router-dom";
import AdSenseAd from "../../components/Adsense/adSenseAd";
import FeedBackForm from "./components/FeedBackForm";

const Unintall = () => {
  // get dynamically heigth and width for adsense
  const [calculatedWidth, setCalculatedWidth] = useState(0);
  const adsenseOuterRef = useRef(null);
  const middleSectionRef = useRef(null);
  useEffect(() => {
    if (adsenseOuterRef.current && middleSectionRef.current) {
      const adsOuterWidth = adsenseOuterRef.current.offsetWidth;
      const middleSectionWidth = middleSectionRef.current.offsetWidth;
      const calculatedWidth = (adsOuterWidth - middleSectionWidth) / 2;
      setCalculatedWidth(calculatedWidth);
    }
  }, []);

  return (
    <div className="uninstall-page" id="root">
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid justify-content-center">
                <Link className="navbar-brand" to="/">
                  <img src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
          <div ref={adsenseOuterRef} className="adsense-outer container-fluid">
            <div className="adsense-bar adsense-left-bar"
              style={{ width: `${calculatedWidth}px`, minWidth: '120px' }}
            >
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="7560981818"
                format="vertical"
                style={{ display: 'block', width: `${calculatedWidth}px`, minWidth: '120px' }}
                responsive={true}
              />
            </div>
            <div className="adsense-bar adsense-right-bar"
              style={{ width: `${calculatedWidth}px`, minWidth: '120px' }}
            >
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="7560981818"
                format="vertical"
                style={{ display: 'block', width: `${calculatedWidth}px`, minWidth: '120px' }}
                responsive={true}
              />
            </div>
            <div className="row">
              <div ref={middleSectionRef} className="star_emoji col-sm-7 mx-auto pt-3">
                <span className="thankEmoji">
                  <span className="emoji">
                    <img src={uninsatllImg} />
                    <span className="emoji_content">
                      <div className="section_title">
                        <h2>
                          Capios{" "}
                          <span className="heading-badge-text">
                            successfully uninstalled
                          </span>{" "}
                          from your device now!
                        </h2>
                        <p>
                          Your experience matters to us. Would you mind taking
                          few minutes to share your feedback on our product?
                        </p>
                      </div>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feedback-section screen_recording_game">
        <div className="row m-0">
          <FeedBackForm />
        </div>
      </div>
    </div>
  );
};

export default Unintall;
