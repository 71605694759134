import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "../views/login/Login";
import Home from "../views/home/Home";
import Dashboard from "../views/dashboard/Dashboard";
import EditVideo from "../views/edit-video/EditVideo";
import Starred from "../views/starred/Starred";
import AboutUs from "../views/about-us/AboutUs";
import ThankYou from "../views/thank-you/ThankYou";
import Uninstall from "../views/uninstall/Uninstall";
import UploadVideo from "../views/upload-video/UploadVideo";
import AdminDashboard from "../admin/views/dashboard/Dashboard";
import UsersList from "../admin/views/users/UsersList";
import MembershipList from "../admin/views/membership/MembershipList";
import AddPricingPlan from "../admin/views/pricing-plan/AddPricingPlan";
import AdminLogin from "../admin/views/login/AdminLogin";
import PrivacyPolicy from "../views/privacy-policy/privacy-policy";
import TagsList from "../admin/views/tags/TagsList";
import SavedSnippets from "../views/saved-snippets/SavedSnippets";
import { localStoragePrefix } from "../utils/config";
import ManageUserProfile from "../admin/views/users/ManageUserProfile";
import ManageImageAndVideo from "../admin/views/media/ManageImageAndVideo";
import ForgotPassword from "../views/login/components/ForgotPassword";
import VerifyEmail from "../views/login/components/VerifyEmail";
import CreateNewPassword from "../views/login/components/CreateNewPassword";
import SignUp from "../views/login/components/SignUp";
import AuthenticatedRoutes from "./authenticatedRoutes";
import LoadingPage from "../views/loading-page/LoadingPage";
import ManagePricingPlans from "../admin/views/pricing-plan/ManagePricingPlans";
import ManageExtensionSettings from "../admin/views/extension-settings/ManageExtensionSettings";
import ManageGeneralSettings from "../admin/views/general-settings/ManageGeneralSettings";
import Callback from "../views/login/components/SocialLogin/Callback";
import { ShareMedia } from "../views/share-media/ShareMedia";
import Screenshot from "../views/screenshot/Screenshot";
import Video from "../views/video/Video";
import MaintenanceMode from "../views/maintenance-mode/maintenance-mode";
import { getGeneralSettingcredentials } from "../redux/actions/configServices";
import MaintenanceModeRoute from "./MaintenanceModeRoute";

const CustomRoutes = () => {
  const isAuth = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );
  const userRole = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserRole"
  );

  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <LoadingPage />}
      <Routes>
          <Route element={<MaintenanceModeRoute />}>
            <Route path="/about-us" element={<AboutUs />} exact />
            <Route path="/uninstall" element={<Uninstall />} exact />
            <Route path="/sign-up" element={isAuth ? <Navigate to="/dashboard" /> : <SignUp />} />
            <Route path="/forgot-password" element={isAuth ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
            <Route path="/verify-email/:token" element={isAuth ? <Navigate to="/dashboard" /> : <VerifyEmail />} />
            <Route path="/create-password/:token" element={isAuth ? <Navigate to="/dashboard" /> : <CreateNewPassword />} />
            <Route path="/dashboard" element={<AuthenticatedRoutes element={Dashboard} />} exact />
            <Route path="/starred" element={<AuthenticatedRoutes element={Starred} />} exact />
            <Route path="/ocr-snippets" element={<AuthenticatedRoutes element={SavedSnippets} />} exact />
            <Route path="/edit-video/:id" element={<AuthenticatedRoutes element={EditVideo} />} exact />
            <Route path="/video-upload" element={<AuthenticatedRoutes element={UploadVideo} />} exact />
            <Route path="/screenshot/:id" element={<Screenshot />} exact />
            <Route path="/video/:id" element={<Video />} exact />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/thank-you" element={<ThankYou />} exact />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
        <Route path="/login" element={isAuth ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/admin/login" element={isAuth ? <Navigate to="/admin/dashboard" /> : <AdminLogin />} />
        <Route path="/share/:id/:type" element={<ShareMedia />} exact />
        <Route path="/dashboard/callback" element={<Callback />}  />
        {/*  */}
        {Number(userRole) === 1 && (
          <Route>
            <Route path="/admin/dashboard" element={<AuthenticatedRoutes element={AdminDashboard} />} />
            <Route path="/admin/manage-users" element={<AuthenticatedRoutes element={UsersList} />} />
            <Route path="/admin/manage-user-profile/:id" element={<AuthenticatedRoutes element={ManageUserProfile} />} />
            <Route path="/admin/manage-image&video" element={<AuthenticatedRoutes element={ManageImageAndVideo} />} />
            <Route path="/admin/manage-membership" element={<AuthenticatedRoutes element={MembershipList} />} />
            <Route path="/admin/manage-pricing-plans" element={<AuthenticatedRoutes element={ManagePricingPlans} />} />
            <Route path="/admin/pricing-plan/create" element={<AuthenticatedRoutes element={AddPricingPlan} />} />
            <Route path="/admin/manage-tags" element={<AuthenticatedRoutes element={TagsList} />} />
            <Route path="/admin/extension-static-ui&text" element={<AuthenticatedRoutes element={ManageExtensionSettings} />} />
            <Route path="/admin/manage-general-settings" element={<AuthenticatedRoutes element={ManageGeneralSettings} />} />
          </Route>
        )}
        <Route path="*" element={isAuth ? <Navigate to="/dashboard" /> : <Login />} />
        {/*  */}
      </Routes>
    </>
  );
};

export default CustomRoutes;