import React, { useState } from "react";
import lightLoader from "../../../assets/images/light-loader.gif";
import { sendFeedBack } from "../../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import {
    failureMessagePopup,
    successMessagePopup,
} from "../../../helper/helper";

const FeedBackForm = () => {
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState("");
    const [loading, setLoading] = useState(false);
    const intialValue = {
        senderEmail: "",
        whyUninstall: {
            message: "The Extension was not woring as expected",
            otherText: "",
        },
        whatToImprove: {
            message: "Improve performance and speed.",
            otherText: "",
        },
        otherTool: "Yes",
        userMessage: "",
        performance: "Very satisfied",
        reInstall: "Yes",
    }
    const [formData, setFormdata] = useState(intialValue);

    const unInstallSchema = ({ formData, setFormErrors }) => {
        const { senderEmail, whyUninstall, whatToImprove } = formData;

        let regex =
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let valid = true;

        const errors = {
            emailError: "",
            whyUninstallError: "",
            whatToImproveError: "",
            otherToolError: "",
        };

        if (!senderEmail) {
            errors.emailError = "Email cannot be empty!";
            valid = false;
        } else if (!regex.test(senderEmail)) {
            errors.emailError = "Invalid email address!";
            valid = false;
        }
        if (whyUninstall?.message === "Other") {
            if (
                !whyUninstall?.otherText ||
                whyUninstall?.otherText?.trim("") === ""
            ) {
                errors.whyUninstallError = "Please write a message!";
                valid = false;
            }
        }
        if (whatToImprove?.message === "Other") {
            if (
                !whatToImprove?.otherText ||
                whatToImprove?.otherText?.trim("") === ""
            ) {
                errors.whatToImproveError = "Please write a message!";
                valid = false;
            }
        }
        setFormErrors(errors);
        return valid;
    };

    const handleSubmit = () => {
        if (unInstallSchema({ formData, setFormErrors })) {
            let whyUninstallMessage = formData?.whyUninstall?.message === 'Other' ? formData?.whyUninstall?.otherText : formData?.whyUninstall?.message;
            let whatToImproveMessage = formData?.whatToImprove?.message === 'Other' ? formData?.whatToImprove?.otherText : formData?.whatToImprove?.message;
            const payload = {
                senderEmail: formData?.senderEmail,
                whyUninstall: whyUninstallMessage,
                whatToImprove: whatToImproveMessage,
                otherTool: formData?.otherTool,
                userMessage: formData?.userMessage,
                performance: formData?.performance,
                reInstall: formData?.reInstall,
            }
            setLoading(true);
            dispatch(
                sendFeedBack(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup(
                            "Submited Successfully!",
                            "Thank you for your feedback! It has been successfully submitted."
                        );
                        setLoading(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message!", "Something went wrong.");
                        setLoading(false);
                    }
                })
            );
        }
    };

    return (
        <div className="conversations-mid extension_screen rounded-0 py-5">
            <div className="feedback-form col-md-7 mx-auto">
                <div
                    className="section_title"
                    style={{
                        textAlign: "center",
                        marginTop: "0%",
                        marginBottom: "2%",
                    }}
                >
                    <h5>Share your feedback with us</h5>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6 text-start">
                        <label htmlFor="inputEmail4">Email Address</label>
                        <input
                            className="form-control mt-2"
                            id="inputEmail4"
                            type="email"
                            name="senderEmail"
                            placeholder="Enter your email address"
                            defaultValue={formData?.senderEmail}
                            onChange={(e) =>
                                setFormdata({
                                    ...formData,
                                    senderEmail: e.target.value,
                                })
                            }
                            maxLength={255}
                        />
                        {formErrors?.emailError && (
                            <span className="text-danger account-dec d-flex">
                                {formErrors?.emailError}
                            </span>
                        )}
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6 text-start">
                        <p>Why did you uinstall Capios</p>
                        <div class="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whyUninstall: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"The Extension was not woring as expected"}
                                type="radio"
                                name="whyUninstall"
                                id="whyUninstall1"
                                defaultChecked
                            />
                            <label className="form-check-label" htmlFor="whyUninstall1">
                                The Extension was not woring as expected
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whyUninstall: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"I found a better alternative"}
                                type="radio"
                                name="whyUninstall"
                                id="whyUninstall2"
                            />
                            <label className="form-check-label" htmlFor="whyUninstall2">
                                I found a better alternative
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whyUninstall: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"It was consuming too many resources."}
                                type="radio"
                                name="whyUninstall"
                                id="whyUninstall3"
                            />
                            <label className="form-check-label" htmlFor="whyUninstall3">
                                It was consuming too many resources.
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whyUninstall: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"I no longer needed the extension."}
                                type="radio"
                                name="whyUninstall"
                                id="whyUninstall4"
                            />
                            <label className="form-check-label" htmlFor="whyUninstall4">
                                I no longer needed the extension.
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whyUninstall: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"Other"}
                                type="radio"
                                name="whyUninstall"
                                id="whyUninstall5"
                            />
                            <label className="form-check-label" htmlFor="whyUninstall5">
                                Other(Please specify)
                            </label>
                        </div>
                        {formData.whyUninstall.message === "Other" && (
                            <div className="form-check">
                                <input
                                    className="form-control"
                                    onChange={(e) =>
                                        setFormdata({
                                            ...formData,
                                            whyUninstall: {
                                                ...formData.whyUninstall,
                                                otherText: e.target.value,
                                            },
                                        })
                                    }
                                    placeholder="Enter your message"
                                    type="text"
                                    name="whyUninstall"
                                    id="whyUninstallMessage"
                                />
                                {formErrors?.whyUninstallError && (
                                    <span className="text-danger account-dec d-flex">
                                        {formErrors?.whyUninstallError}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6 text-start">
                        <p>What Can we do to Improve Capios</p>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whatToImprove: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"Improve performance and speed."}
                                type="radio"
                                name="improve"
                                id="improve1"
                                defaultChecked
                            />
                            <label className="form-check-label" htmlFor="improve1">
                                Improve performance and speed.
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whatToImprove: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"Add more features."}
                                type="radio"
                                name="improve"
                                id="improve2"
                            />
                            <label className="form-check-label" htmlFor="improve2">
                                Add more features.
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whatToImprove: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"Improve user interface."}
                                type="radio"
                                name="improve"
                                id="improve3"
                            />
                            <label className="form-check-label" htmlFor="improve3">
                                Improve user interface.
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whatToImprove: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"Fix bugs and errors."}
                                type="radio"
                                name="improve"
                                id="improve4"
                            />
                            <label className="form-check-label" htmlFor="improve4">
                                Fix bugs and errors.
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        whatToImprove: {
                                            message: e.target.value,
                                        },
                                    })
                                }
                                value={"Other"}
                                type="radio"
                                name="improve"
                                id="improve5"
                            />
                            <label className="form-check-label" htmlFor="improve5">
                                Other(Please specify)
                            </label>
                        </div>
                        {formData?.whatToImprove.message === "Other" && (
                            <div className="form-check">
                                <input
                                    className="form-control"
                                    onChange={(e) =>
                                        setFormdata({
                                            ...formData,
                                            whatToImprove: {
                                                ...formData.whatToImprove,
                                                otherText: e.target.value,
                                            },
                                        })
                                    }
                                    placeholder="Enter your message"
                                    type="text"
                                    name="improve"
                                    id="improveMessage"
                                />
                                {formErrors?.whatToImproveError && (
                                    <span className="text-danger account-dec d-flex">
                                        {formErrors?.whatToImproveError}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-row d-block">
                    <div className="d-flex gap-2 align-items-center">
                        <p className="mb-0 me-2" htmlFor="toolInstead">
                            Are you using any other tool instead of Capios :{" "}
                        </p>
                        <input
                            type="radio"
                            className="btn-check"
                            onChange={(e) =>
                                setFormdata({
                                    ...formData,
                                    otherTool: e.target.value,
                                })
                            }
                            value={"Yes"}
                            name="instead"
                            id="yes"
                            autoComplete="off"
                            defaultChecked
                        />
                        <label className="btn quix-radio-btn" htmlFor="yes">
                            Yes
                        </label>
                        <input
                            type="radio"
                            onChange={(e) =>
                                setFormdata({
                                    ...formData,
                                    otherTool: e.target.value,
                                })
                            }
                            value={"No"}
                            className="btn-check"
                            name="instead"
                            id="no"
                            autoComplete="off"
                        />
                        <label className="btn quix-radio-btn" htmlFor="no">
                            No
                        </label>
                    </div>
                    <div>
                        <textarea
                            className="form-control mt-2"
                            id="toolInstead"
                            type="text"
                            name="toolInstead"
                            placeholder="Enter your message"
                            style={{ maxHeight: "100px" }}
                            onChange={(e) =>
                                setFormdata({
                                    ...formData,
                                    userMessage: e.target.value,
                                })
                            }
                            maxLength={255}
                        />
                    </div>
                </div>
                <div className="form-row satisfied-row">
                    <div className="satisfied-column d-flex gap-2 align-items-center">
                        <p className="mb-0 me-2">
                            How satisfied were you with Capios overall performance?
                        </p>
                        <div className="satisfied-btn d-flex gap-2">
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        performance: e.target.value,
                                    })
                                }
                                value={"Very satisfied"}
                                name="performance"
                                id="verySetisfied"
                                autoComplete="off"
                                defaultChecked
                            />
                            <label className="btn quix-radio-btn" htmlFor="verySetisfied">
                                Very satisfied
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        performance: e.target.value,
                                    })
                                }
                                value={"Satisfield"}
                                name="performance"
                                id="satisfied"
                                autoComplete="off"
                            />
                            <label className="btn quix-radio-btn" htmlFor="satisfied">
                                Satisfield
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        performance: e.target.value,
                                    })
                                }
                                value={"Neutral"}
                                name="performance"
                                id="neutral"
                                autoComplete="off"
                            />
                            <label className="btn quix-radio-btn" htmlFor="neutral">
                                Neutral
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        performance: e.target.value,
                                    })
                                }
                                value={"Dissatisfied"}
                                name="performance"
                                id="disSatisfied"
                                autoComplete="off"
                            />
                            <label className="btn quix-radio-btn" htmlFor="disSatisfied">
                                Dissatisfied
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        performance: e.target.value,
                                    })
                                }
                                value={"Very dissatisfied"}
                                name="performance"
                                id="veryDissatisfied"
                                autoComplete="off"
                            />
                            <label className="btn quix-radio-btn" htmlFor="veryDissatisfied">
                                Very dissatisfied
                            </label>
                        </div>
                    </div>
                </div>
                <div className="reinstall-row form-row">
                    <div className="reinstall-column d-flex gap-2 align-items-center">
                        <p className="mb-0 me-2">
                            Would you consider reinstalling Capios if we addressed your
                            concerns?
                        </p>
                        <div className="reinstall-btn d-flex gap-2">
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        reInstall: e.target.value,
                                    })
                                }
                                value={"Yes"}
                                name="reinstall"
                                id="reinstallYes"
                                autoComplete="off"
                                defaultChecked
                            />
                            <label className="btn quix-radio-btn" htmlFor="reinstallYes">
                                Yes
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        reInstall: e.target.value,
                                    })
                                }
                                value={"No"}
                                name="reinstall"
                                id="reinstallNo"
                                autoComplete="off"
                            />
                            <label className="btn quix-radio-btn" htmlFor="reinstallNo">
                                No
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                onChange={(e) =>
                                    setFormdata({
                                        ...formData,
                                        reInstall: e.target.value,
                                    })
                                }
                                value={"May be"}
                                name="reinstall"
                                id="reinstallMyBe"
                                autoComplete="off"
                            />
                            <label className="btn quix-radio-btn" htmlFor="reinstallMyBe">
                                May be
                            </label>
                        </div>
                    </div>
                </div>
                <div className="btn_section_row">
                    <button
                        className="btn btn-primary btn_cancel_submit submitbtn"
                        type="submit"
                        onClick={() => handleSubmit()}
                        disabled={loading}
                    >
                        Submit
                        {loading && (
                            <img className="loader-image" src={lightLoader} />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FeedBackForm;
