import { getUserDetailsByID } from "../redux/actions/authentication";
import { localStoragePrefix } from "../utils/config";

export const userAuthChecker = (dispatch, accessToken) => {

    if (accessToken) {
        dispatch(getUserDetailsByID(accessToken, (res, type) => {
            if (type === 'fail') {
                localStorage.removeItem(localStoragePrefix + 'screenGeniusAuthToken');
                localStorage.removeItem(localStoragePrefix + 'screenGeniusUserID');
                localStorage.removeItem(localStoragePrefix + 'screenGeniusUserRole');
                localStorage.removeItem(localStoragePrefix + 'screenGeniusAuthTime');
                window.location.href = '/login';
            }
        }));
    } else {
        window.location.href = '/login';
        localStorage.removeItem(localStoragePrefix + 'screenGeniusAuthToken');
        localStorage.removeItem(localStoragePrefix + 'screenGeniusUserID');
        localStorage.removeItem(localStoragePrefix + 'screenGeniusUserRole');
        localStorage.removeItem(localStoragePrefix + 'screenGeniusAuthTime');
    }
};