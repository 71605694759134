import axios from "axios";
import { API_URL } from "../../../utils/config";
import {
    LOADING_GENERAL_SETTINGS,
    GET_GENERAL_SETTINGS_DETAILS,
    UPDATE_GENERAL_SETTINGS,
} from "../type";

// get general settings details
export const getGeneralSettingDetailsAdmin = (adminID) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_GENERAL_SETTINGS,
            });

            let data = JSON.stringify({
                admin_id: adminID,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/general-settings/list`,
                data,
                config
            );

            if (res?.data?.status === true) {
                dispatch({
                    type: GET_GENERAL_SETTINGS_DETAILS,
                    payload: res?.data,
                });
            }
        } catch (error) {
            console.log(error, "---error---");
        }
    };
};

// update general settings
export const updateGeneralSettingsAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_GENERAL_SETTINGS,
            });
            let data = JSON.stringify({
                admin_id: payload?.admin_id,
                stripe: payload?.stripe,
                mail: payload?.mail,
                google: payload?.google,
                twitter: payload?.twitter,
                facebook: payload?.facebook,
                linkedin: payload?.linkedin,
                chatGpt: payload?.chat_gpt,
                bard: payload?.bard,
                notion: payload?.notion,
                slack: payload?.slack,
                dropbox: payload?.dropbox,
                evernote: payload?.evernote,
                microsoftazure: payload?.microsoft_azure,
                maintenance : payload?.maintenance 
            });
            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const res = await axios.post(
                `${API_URL}/admin/general-settings/add-general-setting`,
                data,
                config
            );
            if (res?.data?.status === true) {
                dispatch({
                    type: UPDATE_GENERAL_SETTINGS,
                    payload: res?.data,
                });
                callBack("success", res?.data);
            }
        } catch (error) {
            let message = error?.response?.data;
            callBack("failed", message);
            console.log(error, "--error--");
        }
    };
};
