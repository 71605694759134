import { checkNullValue, checkServerURL } from "../../../helper/helper";
import { API_URL } from "../../../utils/config";
import trashIcon from "../../assets/images/tash-user-icon.png";
import suspendUserIcon from "../../assets/images/block-icon.png";

export const adminTagCSV = (tagsData) => {
    const tagsDataCSVFormate =
        tagsData &&
        tagsData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                User_ID: item?.user_id ? item?.user_id : "N/A",
                Name: item?.userDetails?.name ? item?.userDetails?.name : "N/A",
                Email: item?.userDetails?.email ? item?.userDetails?.email : "N/A",
                Picture: item?.userDetails?.picture
                    ? checkServerURL(API_URL, item?.userDetails?.picture)
                    : "N/A",
                Tag_Name: item?.name ? item?.name : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });

    let tagsDataCSV = { filename: "admin-tags-list", data: tagsDataCSVFormate };
    return tagsDataCSV;
}

export const userTagCSV = (userTagsData) => {
    const usersTagsDataCSVFormate =
        userTagsData &&
        userTagsData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                User_ID: item?.user_id ? checkNullValue(item?.user_id) : "N/A",
                Name: item?.userDetails?.name ? checkNullValue(item?.userDetails?.name) : "N/A",
                Email: item?.userDetails?.email ? checkNullValue(item?.userDetails?.email) : "N/A",
                Picture: item?.userDetails?.picture
                    ? checkNullValue(checkServerURL(API_URL, item?.userDetails?.picture))
                    : "N/A",
                Tag_Name: item?.name ? checkNullValue(item?.name) : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });

    let usersTagsDataCSV = {
        filename: "users-tags-list",
        data: usersTagsDataCSVFormate,
    };
    return usersTagsDataCSV;
}

export const csvColumns = [
    { id: "ID", title: "ID" },
    { id: "User_ID", title: "User_ID" },
    { id: "Name", title: "Name" },
    { id: "Email", title: "Email" },
    { id: "Picture", title: "Picture" },
    { id: "Tag_Name", title: "Tag_Name" },
    { id: "Created_at", title: "Created At" },
    { id: "Updated_at", title: "Updated At" },
]

export const tagStatusHandler = (status) => {
    switch (Number(status)) {
        case 1:
            return {
                status: "Active",
                class: "table-status-active table-status-btn",
            };
        case 0:
            return {
                status: "Suspended",
                class: "table-status-suspend table-status-btn",
            };
        default:
            return {
                status: "Unregistered User",
                class: "table-status-default table-status-btn",
            };
    }
};

export const confirmationModalMessage = ({ action, type }) => {
    switch (action) {
        case "DELETE":
            return {
                title: "Confirm Deletion",
                message: <>Are you sure you want to <br /> permanently delete this Tag?</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_DELETE":
            return {
                title: "Confirm Deletion",
                message: <>Are you sure you want to <br /> permanently delete selected Tag?</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "SUSPEND":
            return {
                title: 'Are You Sure to Suspend the Tag?',
                message: <>Proceed with before taking any steps<br /> to suspend a Tag.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        case "MULTI_SUSPEND":
            return {
                title: 'Are You Sure to Suspend selected Tag?',
                message: <>Proceed with before taking any steps<br /> to suspend selected Tag.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        case "MARK_ADMIN":
            return {
                title: 'Are You Sure to Mark as Admin Tag?',
                message: <>Are you sure you want to <br />mark as admin Tag.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        case "ACTIVATE":
            return {
                title: 'Reactivate Tag?',
                message: <>Are you sure you want to reactivate <br />the Tag.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        default:
            return null;
    }
}