import React from "react";

const FrequentlyAskedQuestions = () => {
  return (
    <div className="container-fluid frequently_asked_questions">
      <div className="container" id="frequently-asked-questions">
        <div className="row">
          <div className="section_title">
            <h2>Frequently asked questions</h2>
          </div>
          <div className="col-lg-6 mx-auto">
            <div className="accordion" id="faq-accodions-exp">
              <div className="accordion-item">
                <div className="accordion-header">
                  <h5 className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordin-one" aria-expanded="true" aria-controls="faq-accordin-one">
                    Is Capios free? Any number limit on the screenshots & recordings I do?
                  </h5>
                </div>
                <div id="faq-accordin-one" className="accordion-collapse collapse show" data-bs-parent="#faq-accodions-exp">
                  <div className="accordion-body">
                    <p>Yes, it's absolutely free as of now, so take advantage of that as much as you can.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <h5 className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-two" aria-expanded="false" aria-controls="faq-accordion-two">
                    Can I be worry-free that my data is safe with Capios?
                  </h5>
                </div>
                <div id="faq-accordion-two" className="accordion-collapse collapse" data-bs-parent="#faq-accodions-exp">
                  <div className="accordion-body">
                    <p>No doubt for that. Your data safety & security is our top priority. You can relax, your information is in safe hands.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <h5 className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-three" aria-expanded="false" aria-controls="faq-accordion-three">
                    How can I upgrade to the premium version?
                  </h5>
                </div>
                <div id="faq-accordion-three" className="accordion-collapse collapse" data-bs-parent="#faq-accodions-exp">
                  <div className="accordion-body">
                    <p>Premium version is on the card. As this is a beta launch, we'll soon notify you once upgrade is available.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <h5 className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-four" aria-expanded="false" aria-controls="faq-accordion-four">
                    What if I got a special feature request?
                  </h5>
                </div>
                <div id="faq-accordion-four" className="accordion-collapse collapse" data-bs-parent="#faq-accodions-exp">
                  <div className="accordion-body">
                    <p>We're all ears for that. Though we can't promise the timelines but we pay attention to all ideas. Please submit your request in feedback section.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <h5 className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-six" aria-expanded="false" aria-controls="faq-accordion-six">
                    Can I access Capios from all of my devices?
                  </h5>
                </div>
                <div id="faq-accordion-six" className="accordion-collapse collapse" data-bs-parent="#faq-accodions-exp">
                  <div className="accordion-body">
                    <p>A big yes to that. Access from any device without any restrictions.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header">
                  <h5 className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-five" aria-expanded="false" aria-controls="faq-accordion-five">
                    Till when my generated share links will be valid?
                  </h5>
                </div>
                <div id="faq-accordion-five" className="accordion-collapse collapse" data-bs-parent="#faq-accodions-exp">
                  <div className="accordion-body">
                    <p>All your screenshots & screen recordings links are here to stay, as long as you want to keep them in public.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
